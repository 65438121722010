#center {
    text-align: center;
}
#img-qrcode{
    padding: 12px;
}

thead {
    padding: 0px;
    margin: 0px;
    background-color: rgb(91, 91, 93);
    color: white;
    font-size: 14px;
    border: 1px solid white;
}

tr {
    font-size: 12px;
}

td {
    font-size: 12px;
}