
#center {
    text-align: center;
}
#header-contas-receber {
    background-color: gray;
    padding: 10px;
    color: aliceblue;
   font-size: 12px;
}
thead {
    padding: 0px;
    margin: 0px;
    background-color: rgb(91, 91, 93);
    color: white;
    font-size: 12px;
    border: 1px solid white;
}
tr {
      font-size:12px;
}

td {
      font-size:12px;
}

