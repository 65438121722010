:root {
    --main-white-color:#fff;
    --font-size:18px;
}
.header-home{
    display: flex;
    align-items: center;
    justify-content: left;
    padding-bottom: 3px;
    background-color:#0c28c4;;
}
.header-home-carrinho {
    margin-left: 8px;
    font-size: var(--font-size);
    color:var(--main-white-color);
}
.header-home-sub-total {
    padding: 9px;
    color:var(--main-white-color);
}
.header-contact{
    font-size: var(--font-size);
    padding: 6px;
    color:var(--main-white-color);
    text-decoration: none;
}
@media screen and (max-width:768px) {
    .header-contact{
        font-size: 16px;
    }
}
#header-frete{
    margin-left: 26px;
    font-size: var(--font-size);
    color:var(--main-white-color);
}