.container-global {
    display: flex;
    justify-content: center;
    padding: 6px;
    margin-top: 16px;
    width: 100%;
    height: center;
}

.main-global {
    display: flex;
    flex-direction: column;
    align-items: center;
    border:black solid 1px;
    background-color: white;
    padding: 8px 8px 8px 8px;
    border-radius: 4px;
    width: 360px;
}

@media screen and (max-width:768px) {
    .main-global {
        font-size: 12px;
    }
}

.main-global-form input {
    color:black;
    margin: 7px;
    width: 18rem;
    height: 2.5rem;
    float: center;
    margin: 3px 20px;
}

@media screen and (max-width:768px) {
    .main-global-form input {
        height: 28px;
    }
}

.main-global-form button {
    align-items: center;
    width: 18rem;
    height: 2.5rem;
    margin: 2px;
    float: center;
    margin: 3px 20px;
}

.main-global-form label {
    color: red;
    font-size: 12px;
    float: center;
    margin: 3px 20px;
}

.main-global-form a {
    color: blue;
    font-size: 12px;
    text-decoration: none;
    padding-top: 12px;
    float: center;
    margin: 3px 20px;
}

.main-global-form dd {
    color:rgb(7, 7, 87);
    font-size: 12px;
    padding-top: 3px;
    float: center;
    margin: -3px 20px;
}

.main-global-form strong {
    padding: 1px 10px 1px 10px;
}

.main-global-form span,
strong {
    color: rgb(8, 8, 112);
}

button {
    color: white;
    background-color: blue;
    font-size: 16px;
    border-radius: 2px;
    border: none;
}

button:hover {
    background-color: rgb(172, 170, 170);
}

div input {
    border: 1px gray solid;
    padding: 1px 1px 1px 6px;
    font-size: 12px;
}

.payment-sale-img-qrcode {
    position: relative;
    top: 1px;
    left: 6rem;
    width: 8rem;
    height: 8rem;
    border-radius: 4px;
    border: 1px solid rgba(33, 31, 31, 0.178);
    float: center;
}
