.btn-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1rem;
}

.btn-button{
    width:24rem;
    height:2rem;
    font-size:1rem;
    border:none;
    border-radius: 6px;
    color: white;
}