.nav-link {
    /* color: black; */
    margin: 1px;
}

.nav-link:hover {
    /* color: rgb(34, 34, 147); */
    padding: 1px;
}

#nav-nav {
    background:none
}

#button-1 {
    background: none;
    border: 1px solid white;
}

#nav-calendar {
    font-size: 12px;
    margin: 16px
}