#container-dashboard
{
    /* display: flex; */
    justify-content: center;
    background-color: #fff;
}
#form-dashboard{
    display: flex;
    flex-direction: column;
    align-items: left;
    /* border: solid rgb(204, 185, 185) 1px; */
    width: 100%;
    height: 100%;
    padding: 26px;
}

#form-dashboard
 button{
    height: 48px;
    margin: 3px;
}

#form-dashboard
 label{
    margin-top:16px;
    font-size: 12px;
}
#itens-store-car {
    display: flex;
    margin: 18px;
    padding: 18px;
    border-radius: 12px;
    flex-Direction: column;
    align-Items: center;
    background-color: rgb(224, 224, 233);
    color:blue;
}
